jQuery(function ($) {
    $(document).tooltip({
        selector: '[data-toggle="tooltip"]'
    }); 
});

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
});

$(function () {
    $.validator.addMethod("aceeptterms", function (value, element) {
        return element.checked;
    });

    $.validator.unobtrusive.adapters.addBool("aceeptterms");
}(jQuery));

function refreshTooltip() {
    $('[data-toggle="tooltip"]').tooltip('dispose');
    $('[data-toggle="tooltip"]').tooltip();
} 