$(function () {
    $("#Name, #Email, #Content").on("change", function () {
        $("#Message").text("");
    });

    $("#contactForm").on("submit", function () {
        if ($("#contactForm").valid()) {
            $("#submitSiteButton").attr("disabled", true);
            $("#submitButtonText").text("Sending...");

            $.ajax({
                url: '/Contact/Send',
                type: 'POST',
                data:
                {
                    "Name": $("#Name").val(),
                    "Email": $("#Email").val(),
                    "Content": $("#Content").val()
                },
                dataType: 'json',
                success: (response) => {
                    $("#Message").text(response);
                    $("#Name").val("");
                    $("#Email").val("");
                    $("#Content").val("");

                    $("#submitSiteButton").attr("disabled", false);
                    $("#submitButtonText").text("Send");
                },
                error: (xhr) => {
                    $("#Message").text(xhr.responseText);

                    $("#submitSiteButton").attr("disabled", false);
                    $("#submitButtonText").text("Send");
                }
            });
        }
        return false;
    });
});