$(function () {
    $('#updateEntryDescriptionForm').unbind('submit').on("submit", function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if ($("#updateEntryDescriptionForm").valid()) {
            $("#submitSiteButton").attr("disabled", true);
            $("#submitButtonText").text("Updating...");

            $.post("/Entry/UpdateDescription",
                {
                    "EntryId": $("#EntryId").val(),
                    "NewDescription": $("#NewDescription").val()
                })
                .done(() => window.location.href = "/entry/updateDescriptionSummary?id=" + $("#EntryId").val())
                .fail((xhr) => {
                    $("#Error").text(xhr.responseText.replace(/"/g, ''));
                    $("#submitSiteButton").attr("disabled", false);
                    $("#submitButtonText").text("Update");
                });
        }
    });
});