$(function () {
    $("#newEntryForm #CategoryId").on("change", function (e) {
        var subCategories = JSON.parse(SubCategories);
        $("#SubCategoryId").empty();
        $("#SubCategoryId").append("<option value=''></option>");
        for (var i = 0, l = subCategories.length; i < l; i++) {
            if (subCategories[i].ParentCategoryId === parseInt($("#CategoryId").val())) {
                $("#SubCategoryId").append("<option value='" + subCategories[i].Id + "'>" + subCategories[i].Name + "</option>");
            }
        }
    });

    $('#newEntryForm').unbind('submit').on("submit", function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        $("#Error").text('');
        if ($("#newEntryForm").valid()) {
            var selectedSubscriptionPrice = $('input[name=subscriptionTypeRadioButton]:checked').parent().children('[name=subscriptionTypePrice]').text();
            var selectedSubscriptionDuration = $('input[name=subscriptionTypeRadioButton]:checked').parent().children('[name=subscriptinoTypeDuration]').text();

            $("#selectedSubscription").text(selectedSubscriptionPrice + " - " + selectedSubscriptionDuration + (selectedSubscriptionDuration === "Annual" ? " (recurring)" : ""));
            $("#paymentConfirmationModal").modal("show");
        }
        else {
            $('html, body').animate({
                scrollTop: ($('.input-validation-error').offset().top - 300)
            }, 2000);
        }
        return false;
    });

    $("#payButton").on("click", function () {
        if ($("#newEntryForm").valid()) {
            $("#paymentConfirmationModal").modal("hide");
            $("#submitSiteButton").attr("disabled", true);
            $("#submitButtonText").text("Adding...");

            $.ajax({
                url: "/Entry/Add",
                type: 'POST',
                data:
                {
                    "Url": $("#Url").val(),
                    "Title": $("#Title").val(),
                    "Description": $("#Description").val(),
                    "CategoryId": $("#CategoryId").val(),
                    "SubCategoryId": $("#SubCategoryId").val(),
                    "SubscriptionTypeId": parseInt($('input[name=subscriptionTypeRadioButton]:checked').val()),
                    "Tags": getTags(),
                    "AcceptTerms": $("#AcceptTerms").is(':checked'),
                    "Email": $("#Email").val()
                },
                dataType: 'json',
                success: (response) => window.location.href = response,
                error: (xhr) => {
                    alert(xhr.responseText.replace(/"/g, ''));
                    $("#submitSiteButton").attr("disabled", false);
                    $("#submitButtonText").text("Submit");
                }
            });
        }
        else {
            $('html, body').animate({
                scrollTop: ($('.input-validation-error').offset().top - 300)
            }, 2000);
        }
        return false;
    });

    function getTags() {
        var tags = [];
        if ($("#Tags").val().indexOf(',') !== -1) {
            tags = $("#Tags").val().split(/,/g);
        }
        else {
            tags = $("#Tags").val().split(/\s/g);
        }
        for (var i = 0; i < tags.length; i++) {
            tags[i] = tags[i].trim();
        }
        return tags;
    }
});