$(function () {
    $("#entryExtensionForm #NewSubscriptionId").on("change", function (e) {
        var subscription = $("#NewSubscriptionId").val().split("&")[1];
        if (subscription === "Annual")
            $("#validTo").show();
        else
            $("#validTo").hide();
    });

    $('#entryExtensionForm').unbind('submit').on("submit", function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        if ($("#entryExtensionForm").valid()) {
            $("#extendSubscriptionButton").attr("disabled", true);
            $("#extendButtonText").text("Saving...");

            $.ajax({
                url: '/Entry/Extend',
                type: 'POST',
                data:
                {
                    "Entry.Id": $("#Entry_Id").val(),
                    "NewSubscriptionTypeId": $("#NewSubscriptionTypeId").val()
                },
                dataType: 'json',
                success: (response) => window.location.href = response,
                error: () => {
                    $("#Error").text(xhr.responseText.replace(/"/g, ''));
                    $("#submitSiteButton").attr("disabled", false);
                    $("#submitButtonText").text("Send");
                    $("#extendSubscriptionButton").attr("disabled", false);
                    $("#extendButtonText").text("Renew");
                }
            });
        }
    });
});