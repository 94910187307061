$(function () {
    $("#CountryId").on("change", function () {
        if ($("#CountryId").val().split("&")[1] === "True") {
            $("#vatUeContainer").removeAttr("hidden");
        }
        else
            $("#vatUeContainer").attr("hidden", true);
    });

    $('#invoiceForm').unbind('submit').on("submit", function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        $("#Error").text('');

        if ($("#invoiceForm").valid()) {
            $("#submitSiteButton").attr("disabled", true);
            $("#submitButtonText").text("Adding..."); 
             
            $.ajax({
                url: '/Invoice/Add',
                type: 'POST',
                data:
                {
                    "Company": $("#Company").val(), 
                    "City": $("#City").val(),
                    "ZipCode": $("#ZipCode").val(),
                    "Address": $("#Address").val(),
                    "CountryId": $("#CountryId").val().split('&')[0],
                    "PaymentId": $("#PaymentId").val(),
                    "Email": $("#Email").val(),
                    "VatUE": $("#VatUE").val()
                },
                dataType: 'json',
                success: () => window.location.href = "/Invoice/Summary?id=" + $("#PaymentId").val(),
                error: (xhr) => {
                    $("#Error").text(xhr.responseText.replace(/"/g, ''));
                    $("#submitSiteButton").attr("disabled", false);
                    $("#submitButtonText").text("Send");
                }
            });
        }
        return false;
    });
});